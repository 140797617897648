export const AzureProvider = "AzureAD";
export const CRM_API = "crmApi";
export const PROJECT_API = "projectApi";
export const SUGGEST_API = "suggestApi";
export const TODO_API = "todoApi";
export const TOPHAT_API = "tophatApi";
export const QUICKSIGHT_API = "quicksightApi";
export const CHECKLISTRECORD_API = "checklistRecordApi";
export const ALL_ITEMS = "All Items";
export const PAGE_SIZE = 20;
export const LEAD_SHORT_TO = 8;
export const LEAD_MEDIUM_TO = 22;

export const COST_SHORT_TO = 50;
export const COST_MEDIUM_TO = 150;
export const BUCKET_TOPHAT = "dev-mvp-data-platform-fragmented-data-ingestions";

export const DATE_FORMAT = "DD/MM/YYYY";

export const MOCK_IMAGES = [
  "Public/test_uc2/images/image1.jpg",
  "Public/test_uc2/images/image2.png",
  "Public/test_uc2/images/image3.jpg",
  "Public/test_uc2/images/image4.png"
];
export const MOCK_DOCS = [
  "Public/test_uc2/documents/doc_test_1.pdf",
  "Public/test_uc2/documents/doc_test_2.pdf",
  "Public/test_uc2/documents/doc_test_3.pdf"
];

export const materialTypes = [
  { value: "ZHAL", label: "Semi Finished Material" },
  { value: "ZROH", label: "Raw Material" }
];

export const materialTypesLookup = {
  ZHAL: "Semi Finished Material",
  ZROH: "Raw Material"
};

export const materialTypesName = {
  SemiFinishedMaterial: "ZHAL",
  RawMaterial: "ZROH"
};

export const GALAXY_TAB_A7_BREAKPOINT_W = 1340;
// const GALAXY_TAB_A7_BREAKPOINT_H = 800
export const FOLDER = "Public/";
export const DIR_TO_DOC = "test_uc2/documents/";

export const GET_PROJECT_PATH = "/api/project";
export const STAGE_GATE_PATH = "/api/project/stageGate";
export const TODO_PATH = "/api/todo";
export const MAIN_PIC_PROJECT_PATH = "/mainPICProject";
export const DELETE_MAIN_PIC_PROJECT_PATH = "/mainPICProject/delete";
export const GET_PIC_PROJECT_PATH = "/mainPICProject/assignedPicUser";
export const GET_PLANT_PATH = "/api/checklistRecord/plant";
export const YARD_PATH = "/api/checklistRecord/yard";
export const GET_CLIENT_LIST_PATH = "/api/suggest/clientName";
export const GET_DASHBOARD_EMBED_URL = "/api/quicksight/getDashboardEmbedUrl";
export const PUT_IS_FAVOURITE_PATH = "/api/project/isFavourite";
export const CHECKLIST_SUGGESTION_PATH = "/api/suggest/suggestedChecklist";

// CRM API
export const CRM_OPPORTUNITY_PATH = "/crm/opportunities";
export const CRM_GET_NEXT_ACTION_PATH = "/crm/project-allocations/opportunities";
export const CRM_CONFIG_BUILDING_TYPES_PATH = "/crm/configs/building-types";
export const CRM_APP_ROLES = "/crm/app-roles";
export const CRM_PROJECT_ALLOCATION_ALL_PATH = "/crm/project-allocations/productions";
export const CRM_FFORM_PATH = "/crm/f-forms/";
export const CRM_DEPLOY_ALLOCATION_ALL_PATH = "/crm/project-allocations/deployments";
export const CRM_PROJECT_ALLOCATION_PATCH_FACTORY_CAPCITY = "/crm/project-allocations/factory-capacity";
export const CRM_OPPORTUNITY_PATH_COMMON = "/crm/opportunities/common";
export const CRM_CONFIG_CURRENCIES_PATH = "/crm/configs/currencies";
export const CRM_CONFIG_ADD_SUBTYPES_PATH = "/crm/configs/sub-building-types";
export const CRM_CONFIG_DELETE_SUBTYPES_PATH = "/crm/configs/sub-building-types";
export const CRM_CONFIG_EDIT_SUBTYPES_PATH = "/crm/configs/sub-building-types";
export const CRM_OPPORTUNITY_PATH_COMMON_CLIENT_NAME = "/crm/opportunities/common/clientName";
export const CRM_OPPORTUNITY_PATH_COMMON_CONTACT = "/crm/opportunities/common/contact";
export const CRM_ADD_CLIENT = "/crm/clients/add";
export const CRM_ADD_CONTACT = "/crm/contacts/add";
export const CRM_ADD_SERVICE_TYPE = "/crm/opportunities/addServiceType";
export const CRM_ADD_PROCUREMENT_METHOD = "/crm/opportunities/addProcurementMethod";
export const CRM_CHECK_DUP_PROJECT_NAME = "/crm/opportunities/projectName/exists";
export const CRM_VALIDATE_CONTACT_NAME = "/crm/contacts/isValidContactName";
export const CRM_VALIDATE_CLIENT_NAME = "/crm/clients/isValidClientName";
export const POST_OPPORTUNITY_NOTE = "/crm/opportunities/notes/add";
export const UPDATE_OPPORTUNITY_NOTE = "/crm/opportunities/notes/update";
export const DELETE_OPPORTUNITY_NOTE = "/crm/opportunities/notes";
export const GET_OPPORTUNITY_DETAIL = "/crm/opportunities/detail";
export const CRM_UPDATE_PAYMENT_SCHEME = "/crm/opportunities/paymentScheme";
export const CRM_UPDATE_MAIN_PIC = "/crm/opportunities/mainPIC";
export const CRM_UPDATE_KEY_TERMS = "/crm/opportunities/keyTerms";
export const CRM_GET_PROCUREMENT_COMMON = "/crm/opportunities/common/procurementMethod";
export const CRM_GET_NATURE_OF_CLIENT_COMMON = "/crm/opportunities/common/natureOfClient";
export const CRM_GET_LIST_CONVERSION_RATE = "/crm/opportunities/salesForecastAssumption";
export const CRM_ADD_SALES_FORECAST_ASSUMPTION = "/crm/opportunities/salesForecastAssumption";
export const CRM_UPDATE_SALES_FORECAST_ASSUMPTION = "/crm/opportunities/salesForecastAssumption";
export const CRM_DELETE_SALES_FORECAST_ASSUMPTION = "/crm/opportunities/salesForecastAssumption";
export const CRM_GET_TIMELINE_FORECAST_ASSUMPTION = "/crm/opportunities/salesTimeLineAssumption";
export const CRM_GET_LIST_NEXT_ACTION = "/crm/project-allocations/next-actions";
export const CRM_VALIDATE_SERVICE_TYPE = "/crm/project-allocations/opportunities/service-types/exists";
export const CRM_VALIDATE_PROCUREMENT_METHOD = "/crm/project-allocations/opportunities/procurements/exists";
export const CRM_PROJECT_ALLOCATIONS_COMBINED = "/crm/project-allocations";

export const QS_DASHBOARD_PROJECT_OVERVIEW = "9b84f527-4381-442f-b127-3d9d6150ccdc";
export const QS_DASHBOARD_MOCKUP_DEPLOYMENT_TRACKER = "a35bdb1c-7ca1-4c03-9693-e25cd6c8333f";
export const QS_DASHBOARD_QUALITY_OVERVIEW = "800730d4-82a7-42e1-a9cf-9969b02468bd";
export const QS_DASHBOARD_QUALITY_ISSUE_REPORT = "0856ec88-90a4-478f-a9b1-875722bb985c";
export const QS_DASHBOARD_EXECUTIVE = "caa29adc-351b-435f-b58d-18fd927a232a";
export const QS_DASHBOARD_PRODUCTION = "c083b2ff-390e-4619-afa9-1067fef9a1ec";
export const QS_PHASING_REPORT = "49dc4734-89d3-484e-a98c-41332262c15e";
export const QS_DASHBOARD_SALES = "c25de160-4c41-456e-b0b7-09bf806198d8";
export const QS_DASHBOARD_KPI_DASHBOARD = "7389292e-3031-4c5e-8496-f68b0afd212a";
export const QS_DASHBOARD_LOGISTIC_KPI_DASHBOARD = "03f28214-8bf3-4794-9f41-9e37f21ec53d";

export const FOLDER_FFORM_FILES = "crm/accountManagement/f-form";
// For UAT: "UAT Inspection Team";
// For PRODUCTION: "PROD Inspection Team";
export const INSPECTION_TEAM_NAME = "Inspection Team";
