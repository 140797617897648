/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:796cc36b-ea0b-4c8d-b133-ba02830c6098",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_QXyG31oPv",
    "aws_user_pools_web_client_id": "6sjifcprvpkd40mkb6i847icr2",
    "oauth": {
        "domain": "tophat-develop.auth.eu-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://dev.tophatmvp.uk/",
        "redirectSignOut": "https://dev.tophatmvp.uk/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "tophatstaticfile181740-develop",
    "aws_user_files_s3_bucket_region": "eu-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "projectApi",
            "endpoint": "https://wanctlrawg.execute-api.eu-west-2.amazonaws.com/develop",
            "region": "eu-west-2"
        },
        {
            "name": "checklistRecordApi",
            "endpoint": "https://bv7es2vrce.execute-api.eu-west-2.amazonaws.com/develop",
            "region": "eu-west-2"
        },
        {
            "name": "checklistTemplateApi",
            "endpoint": "https://l3jya6fyb9.execute-api.eu-west-2.amazonaws.com/develop",
            "region": "eu-west-2"
        },
        {
            "name": "entityApi",
            "endpoint": "https://7o1a6sfo29.execute-api.eu-west-2.amazonaws.com/develop",
            "region": "eu-west-2"
        },
        {
            "name": "suggestApi",
            "endpoint": "https://y26jdptiof.execute-api.eu-west-2.amazonaws.com/develop",
            "region": "eu-west-2"
        },
        {
            "name": "todoApi",
            "endpoint": "https://h2pf67fxt1.execute-api.eu-west-2.amazonaws.com/develop",
            "region": "eu-west-2"
        },
        {
            "name": "quicksightApi",
            "endpoint": "https://cv0lk0j5r9.execute-api.eu-west-2.amazonaws.com/develop",
            "region": "eu-west-2"
        },
        {
            "name": "checklistApi",
            "endpoint": "https://3v2ujotvwb.execute-api.eu-west-2.amazonaws.com/develop",
            "region": "eu-west-2"
        },
        {
            "name": "tophatApi",
            "endpoint": "https://cdevw33iji.execute-api.eu-west-2.amazonaws.com/develop",
            "region": "eu-west-2"
        },
        {
            "name": "crmApi",
            "endpoint": "https://7jru5g1l08.execute-api.eu-west-2.amazonaws.com/develop",
            "region": "eu-west-2"
        }
    ]
};


export default awsmobile;
